.answer {
  border: 2px solid #F2F6F7;
  background: #F2F6F7;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
}

.answer.answer-selected {
  border: 2px solid rgb(25, 118, 210);
}

.answer.answer-correct {
  border: 2px solid #5EC2A6;
  background-color: #5EC2A610;
}

.answer.answer-incorrect {
  border: 2px solid #EB464C;
  background-color: #EB464C10;

}