.mdx-container {
  height: 90%;
  overflow: auto;
  border: 1px solid rgba(189, 189, 189, 50%);
  border-radius: 5px;
}

.option-width {
  flex: 2;
}
