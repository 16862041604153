.answer {
  border: 2px solid #f2f6f7;
  background: #f2f6f7;
  border-radius: 6px;
  display: flex;
  padding: 5px 5px 5px 5px;
  margin-bottom: 0px;
}
.answer-selected {
  border: 2px solid rgb(25, 118, 210);
}

.answer-selected.answer-incorrect {
  border: 2px solid #eb464c;
  background-color: #eb464c10;
}

.answer-selected.answer-correct {
  border: 2px solid #5ec2a6;
  background-color: #5ec2a610;
}
