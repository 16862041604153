.main-question {
    margin: 1em auto;
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.multi-step-answer.answered.correct {
    color: green;
}

.multi-step-answer.correct:not(.answered) {
    color: red;
}

.multistep-instruction {
    white-space: pre-line;
}