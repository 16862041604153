/* Container styling for the text content */
.highlight-container {
  position: relative; /* Necessary for pseudo-elements to position correctly */
  line-height: 1.6;
  font-family: "Times New Roman", Times, serif; /* Traditional font for a legislative feel */
  background-color: #fafafa; /* Light paper-like color */
  border: 1px solid #e0e0e0; /* Subtle border to simulate paper edges */
  padding: 24px;
  border-radius: 8px; /* Rounded corners for a soft paper-like effect */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a raised, paper-like effect */
  overflow: hidden; /* Prevents pseudo-elements from overflowing the container */
}

/* Paper-like background lines */
.highlight-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, transparent 95%, rgba(0, 0, 0, 0.05) 98%, transparent 100%);
  background-size: 100% 24px; /* Adds horizontal lines to simulate ruled paper */
  pointer-events: none; /* Allows clicks to pass through the pseudo-element */
  z-index: 0; /* Stays behind the content */
}

/* Paper grain texture */
.highlight-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03) 1px, transparent 1px, transparent 24px);
  mix-blend-mode: multiply; /* Blends with the background for a subtle paper texture */
  pointer-events: none;
  z-index: 0; /* Ensures the texture is behind the text */
}

/* Basic paragraph, header, and list styles */
.highlight-container p {
  margin: 12px 0;
  font-size: 1.1rem;
  color: #333; /* Darker text color for contrast */
  position: relative;
  z-index: 1; /* Ensures text is above the pseudo-elements */
}

/* Base style for highlighted text */
.highlight-text {
  background-color: yellow;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Style for correct answer highlights */
.correct-highlight-text {
  background-color: #90EE90; /* Light green */
  cursor: default; /* Change cursor to default as these shouldn't be interactive */
}

/* Global hover style for all elements with the same data-highlight-id */
.highlight-text[data-highlight-id]:hover,
.highlight-text[data-highlight-id]:hover ~ .highlight-text[data-highlight-id] {
  background-color: orange;
}

/* Ensure all elements with the same data-highlight-id change color on hover */
.highlight-text[data-highlight-id]:hover ~ .highlight-text,
.highlight-text[data-highlight-id]:hover {
  background-color: orange;
}

/* Target all elements globally with the same data-highlight-id */
.highlight-text[data-highlight-id]:hover,
.highlight-text[data-highlight-id]:hover ~ .highlight-text[data-highlight-id],
.highlight-text:hover[data-highlight-id] {
  background-color: orange;
}

/* Hover style applied via JavaScript */
.highlight-text.hover-active {
  background-color: orange !important;
}

/* Hover style for correct answer highlights */
.correct-highlight-text:hover {
  background-color: #32CD32; /* Lime green for hover state */
}

/* Styling for anchor tags to make them distinct */
.highlight-container a {
  color: blue;
  text-decoration: underline;
}

.highlight-container a:hover {
  text-decoration: none;
}


/* Header and list styles */
.highlight-container h3,
.highlight-container h4 {
  font-weight: bold;
  color: #333;
}

.highlight-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.list-item {
  display: block;
  font-weight: bold;
  margin-bottom: 4px !important;
}

.t1 {
  margin-left: 0 !important;
}

.t2 {
  margin-left: 20px !important;
}

.t3 {
  margin-left: 40px !important;
}

.t4 {
  margin-left: 60px !important;
}