.spreadsheet-cell-underline {
  border-bottom: 1px solid #271a1ac6 !important;
}

.spreadsheet-cell-bold {
  font-weight: 900;
  color: red;
}

.spreadsheet-cell-highlight {
  background-color: #e0f7fa !important;
}

.spreadsheet-cell-incorrect {
  /* background-color: #ffcccb; */
  background-color: #ffff00 !important;
}
/* .Spreadsheet {
  --readonly-text-color: #000000;
} */

.Spreadsheet__cell--readonly {
  color: #000000 !important;
}
