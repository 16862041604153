.top-bar-wrapper {
  height: 70px;
  padding-left: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
}

.top-bar-logo {
  height: 70px;
}
