.module-edit-wrapper {
  margin-top: 10px;
  width: calc(100vw - 80px);
}

.module-edit-body {
  background: #ffffff;
  padding: 40px;
}

.module-line-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.lesson-list-drag-target {
  height: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}
.lesson-list-drag-target.active {
  outline: 2px dotted #1976d2;
  height: 10px;
}

.lesson-list-drag-target.over {
  outline: 4px dotted #1976d2;
}
