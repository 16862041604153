.login-wrapper {
  background: #F2F6F7;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}
.login {
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 50px;
  padding: 50px 125px;
}
.logo {
  margin-top: 95px;
  width: 250px;
}
