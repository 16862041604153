.dial-viewport-wrapper {
  border: 1px solid #A9B3BE;
  border-radius: 5px;
  flex: 1 1 1;
  height: 202px;
  width: 202px;
}
.dial-viewport {
  border: 5px solid #E4E8E9;
  border-radius: 5px;
  flex: 1 1 1;
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: relative;
}
.dial-viewport:before {
  box-shadow: inset -3em 2.5em 4em #F2F6F7;
  content: '';
  display: block;
  height: 50%;
  width: calc(100% + 100px);
  top:0;
  opacity: 0.7;
}
.dial-viewport:after {
  box-shadow: inset -3em -2.5em 4em #0a264533;
  content: '';
  display: block;
  height: 50%;
  width: calc(100% + 100px);
  top:0;
  opacity: 0.7;
}

.dial-wrapper {
  position: absolute;
}
.dial-option {
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.dial-image {
  width: 80px;
}