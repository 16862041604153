.lesson-edit-wrapper {
  margin-top: 10px;
  width: calc(100vw - 80px);
}

.lesson-edit-body {
  background: #ffffff;
  padding: 40px;
}

.lesson-element-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  align-items: baseline;
}
