.question-form {
  width: 100%;
}
.question-mui-wrapper {
  width: 100%;
  max-width: 1000px;
}
.question-text p {
  margin: 0px;
  padding: 0px;
}
.mcq-item {
  border: 2px solid #f2f6f7;
  background: #f2f6f7;
  border-radius: 6px;
  display: flex;
  padding: 0px 5px 0px 5px;
  margin-bottom: 10px;
}

.mcq-item-selected {
  border: 2px solid rgb(25, 118, 210);
}

.mcq-item-correct {
  border: 2px solid #5ec2a6;
  background-color: #5ec2a610;
}

.mcq-item-incorrect {
  border: 2px solid #eb464c;
  background-color: #eb464c10;
}

.mcq-item-correct > span > .markdown {
  color: #0a2645;
}

.mcq-item-incorrect > span > .markdown {
  color: #0a2645;
}
